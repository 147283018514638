import React from 'react'
import Layout from '../Layout'
import View from '../View'
import  Moment from 'moment'
import { getCurrentUser } from '../../utils/auth'
import { getEmailLog } from '../../utils/email'
import WaitLoader from '../WaitLoader'
import EmailPreview from '../EmailPreview'

class EmailLogEntry extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      to: 'example@forms-surfaces.net',
      log: {},
      events: [],
      failedEvents: [],
      opened: null,
      user: {},
      errorMessage: '',
      loading: true
    }
  }

  componentDidMount() {
    var user = getCurrentUser()
    this.setState({
      user: user
    })
    getEmailLog({logid: this.props.logid}, function(err, response){
      if(err){
        this.setState({errorMessage: err.message})
      }
      if(response){
        if(response.log){
          if(response.log.errorMessage){
            this.setState({errorMessage: response.log.errorMessage})
          }else{
            let clicks = response.log.events.filter(event => event.eventType === 'clicked')
            let failed = response.log.events.filter(event => event.eventType === 'failed')
            let openedEvents = response.log.events.filter(event => event.eventType === 'opened')

            let opened = null
            if(openedEvents.length > 0){
              opened = openedEvents[0].timeStamp
            }
            this.setState({
              log: response.log,
              events: clicks,
              failedEvents: failed,
              opened: opened,
              loading: false
            })
          }
        }
      }else{
        this.setState({errorMessage: 'Log not found'})
      }
    }.bind(this))
  }

  render(){
    const clickRows = this.state.events.map((click)=>
      <tr key={click._id}>
      <td>{Moment(click.created).local().format("MM-DD-YYYY hh:mm:ss a")}</td>
      <td><a target="_blank" rel="noopener noreferrer" href={click.url}>{click.url}</a></td>
      </tr>
    )
    let logClicks = <table><tbody>
    <tr><th>Clicked Date</th><th>Url Clicked</th></tr>
    {clickRows}
    </tbody></table>
    let errorMessage = ''
    if(this.state.errorMessage){
      errorMessage = <p className="error">Error: {this.state.errorMessage}</p>
    }
    let details = ''
    let openedDate = ''
    let failedDate = ''
    let preview = ''
    if(this.state.log){
      if(this.state.log.subject){
        if(this.state.opened){
          openedDate = Moment(this.state.opened)
          .local()
          .format("MM-DD-YYYY hh:mm:ss a")
          openedDate = <li>Opened: {openedDate}</li>
        }
        if(this.state.failedEvents.length > 0){
          failedDate = <li>Failed: {Moment(this.state.failedEvents[0].timeStamp)
          .local()
          .format("MM-DD-YYYY hh:mm:ss a")} (<i>{this.state.failedEvents[0].deliveryStatus.description}</i>)
          </li>
        }
        details = <ul>
                  <li>Recipient: {this.state.log.to}</li>
                  <li>Subject: {this.state.log.subject}</li>
                  <li>Sent: {Moment(this.state.log.created)
                  .local()
                  .format("MM-DD-YYYY hh:mm:ss a")}</li>
                  {openedDate}
                  {failedDate}
                </ul>
        preview = <div className="col-xs-12 col-sm-12 col-md-12">
                  <h3>Preview:</h3>
                  <EmailPreview
                    to={this.state.to}
                    subject={this.state.subject}
                    userMessage={this.state.log.userMessage}
                    showUserImage={this.state.log.showUserImage}
                    domain={this.state.log.domain}
                    pages={this.state.log.pagesShared}
                  />
                  </div>
      }
    }

    return (
      <Layout>
      <View title="EMAIL LOG DETAILS" parent={{
        url: '/email/log',
        title: 'EMAIL LOGS'
      }}>
        <div className="row relative">
          <WaitLoader loading={this.state.loading} />
          {errorMessage}
          <div className="col-xs-12 col-sm-12 col-md-6">
          <h3>Details</h3>
            {details}
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
          <h3>Links Clicked</h3>
          {logClicks}
          </div>
        </div>
        <div className="row relative">
          {preview}
        </div>
      </View>
      </Layout>
    )
  }

}

export default EmailLogEntry
