import React from 'react'
import Layout from '../Layout'
import View from '../View'
import { getEmailLogs } from '../../utils/email'
import { DataGrid } from '@mui/x-data-grid'
import Moment from 'moment'
import { Link } from 'gatsby'
import WaitLoader from '../WaitLoader'

class EmailLog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logs: [],
      events: [],
      stats: {},
      errorMessage: '',
      loading: true,
      columns: [
        {
          field: 'sentDate', headerName: 'Sent Date', width: 100,
          renderCell: (params) => (
            <strong>
              {Moment(params.row.created)
              .local()
              .format("MM-DD-YYYY hh:mm:ss a")}
            </strong>
          )
        },
        {
          field: 'to', headerName: 'To Address', width: 170
        },
        {
          field: 'subject', headerName: 'Subject', width: 250
        },
        {
          field: 'status', headerName: 'Status', width: 100
        },
        {
          field: 'opened', headerName: 'Opened', width: 100,
          renderCell: (params) => (
            <strong>
              {Moment(params.row.openedOn)
              .local()
              .format("MM-DD-YYYY hh:mm:ss a")}
            </strong>
          )
        },
        {
          field: 'viewLink', headerName: 'View', width: 140,
          renderCell: (params) => (
            <strong>
              <Link
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                to={'/email/log/'+params.row._id}
                style={{
                  margin: '0'
                }}
              >
                View Email Logs
              </Link>
            </strong>
          )
        }
      ]
    }
  }

  componentDidMount() {
    getEmailLogs(function(err, response){
      if(err){
        console.log(err)
        this.setState({errorMessage: err.message, loading: false})
      }else{

        response.logs.forEach((item, i) => {
          let clicks = 0
          if(item.events){
            if(item.events.find(event => event.eventType === 'delivered')){
              item.status = "Delivered"
            }
            if(item.events.find(event => event.eventType === 'failed')){
              item.status = "Failed"
            }
            if(item.events.find(event => event.eventType === 'clicked')){
              clicks = item.events.filter(event => event.eventType === 'clicked').length
            }
            if(item.events.find(event => event.eventType === 'opened')){
              let opened = item.events.find(event => event.eventType === 'opened')
              item.openedOn = opened.timeStamp
            }
          }
          item.clicks = clicks
        });
        let logs = response.logs
        let events = response.events
        let stats = response.stats
        if(!logs){
          logs = []
        }
        if(!events){
          events = []
        }
        this.setState({
          logs: logs,
          events: events,
          stats: stats,
          loading: false },function(){

        }.bind(this))
      }
    }.bind(this))
  }

  render() {
    let errorMessage = ''
    if(this.state.errorMessage){
      errorMessage = <p className="error">Error: {this.state.errorMessage}</p>
    }
    const stats =  null
    return (
      <Layout>
      <View title="EMAIL LOGS">
      <div className="row col-xs-12">
      <p>Manage your emails logs by sorting the columns below, or click on ' View' to access individual email logs.</p>
      </div>
      <div className="row center-xs">
        <div className="email-form col-xs-12 col-sm-12 col-md-12 relative">
          <WaitLoader loading={this.state.loading} />
          {errorMessage}
          {stats}
          <div style={{ display: 'flex', height: '100vh' }}>
          <div style={{ flexGrow: 1 }}>
          <DataGrid rows={this.state.logs} columns={this.state.columns} getRowId={(row) => row._id} height="500" />
          </div></div>
        </div>
      </div>
      </View>
      </Layout>
    )
  }
}

export default EmailLog
