import React from 'react'
import EmailLog from '../../components/Email/Log'
import EmailLogEntry from '../../components/Email/LogEntry'
import FSRoute from '../../components/FSRoute'
import { isBrowser } from '../../utils/misc'
const App = ({ data }) => {
  if (!isBrowser) return null
  return (
    <>
      <FSRoute
        path="/email/log"
        component={EmailLog}
        fsOnly="false"
        data={data}
      />
      <FSRoute
        path="/email/log/:logid"
        component={EmailLogEntry}
        fsOnly="false"
        data={data}
      />
    </>
  )
}

export default App
